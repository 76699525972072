export const videoLinks = [
  {
    link: "https://youtu.be/r2yWXfAFCz0",
    title: "Megan Holt",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
  {
    link: "https://youtu.be/v6BA9uoFBVM",
    title: "Cam Perry",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
  {
    link: "https://youtu.be/R5mC7dkm6B0",
    title: "Christina Gorton",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
  {
    link: "https://youtu.be/cZywSKZlkf4",
    title: "Kent",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
  {
    link: "https://youtu.be/iQGC0CovTmc",
    title: "Amberley Romo",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
  {
    link: "https://youtu.be/H0ti5kGDcbY",
    title: "Amarachi Ejiawoko",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
  {
    link: "https://youtu.be/to5Ga1_0DD4",
    title: "Amanda Lane",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
  {
    link: "https://youtu.be/LtduvUKbVn8",
    title: "Katie Rose",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
  {
    link: "https://youtu.be/odQrxiiqh0Y",
    title: "Megan Jeffcoat",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
  {
    link: "https://youtu.be/vEs-5zVh-CY",
    title: "Vante",
    description: "Megan has found that relying on the community she has built around her has been a huge help getting her through her fears. She gets strength from her family, women and moms on Twitter, and small groups she's a part of in Lambda.",
    bullets: [
      "Am I really cut out for this?",
      "Get comfortable with being uncomfortable!",
      "Pair-programming has been a life saver - I would have quit by now if it wasn't for that",
      "Seeing people struggle and have the question helps you know you're not alone",
      "Be honest with where you're at",
      "Find or build a community of people who will lift each other up",
      "Don't be afraid to reach out!"
    ]
  },
];
