import React from "react";

import Navbar from "./components/Navbar";
import VideoGrid from "./components/VideoGrid";
import Dictionary from './components/Dictionary';
import Resources from "./components/Resources";

function App() {
  return (
    <div className="flex flex-col bg-gray-100">
      <Navbar />
      <Dictionary />
      <div className="px-2 flex flex-col w-full items-center">
        <VideoGrid />
      </div>
      <Resources />
    </div>
  );
}

export default App;
