import React from "react";

// import "./dictionary.css";

const Dictionary = () => {
  return (
    <div className="lg:w-8/12 w-11/12 px-2 mb-16 ml-8 lg:ml-12 xl:ml-16 text-gray-800">
      <div className="flex align-bottom">
        <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold">impostor syndrome</h1>
      </div>
      <p className="text-l sm:text-xl text-gray-600 align-text-bottom -mt-2 sm:-mt-3 md:-mt-4">or im·post·er syn·drome</p>
      <p className="text-l sm:text-xl align-text-bottom mt-1 md:mt-3">[ im-<span className="font-semibold">pos</span>-ter sin-drohm ]</p>
      <p className="italic mt-1 md:mt-3">noun</p>
      <div className="flex">
        <div className="mr-2 text-gray-600">1</div>
        <p className="flex-grow">anxiety or self-doubt that results from persistently undervaluing one’s competence and active role in achieving success, while falsely attributing one's accomplishments to luck or other external forces.</p>
      </div>
    </div>
  );
};

export default Dictionary;
