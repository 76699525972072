import React from "react";
import ReactPlayer from 'react-player'

import "./video-grid.css";

const Video = ({ video }) => {
  return (
    <div className="lg:w-5/12 w-11/12 mb-16 bg-white rounded-lg overflow-hidden shadow-md p-6 sm:p-8">
      <h2 className="text-2xl text-gray-700">{video.title}</h2>
      <ReactPlayer className="video-player mx-auto my-6 sm:my-8" volume="1" controls url={video.link} />
      <p className="mb-4">{video.description}</p>
      <ul className="ml-4">
        {video.bullets.map(bullet => (
          <li key={bullet}>• {bullet}</li>
        ))}
      </ul>
    </div>
  );
};

export default Video;
