import React from "react";

import { videoLinks } from "../videoLinks";

import Video from "./Video";

const VideoGrid = () => {
  return (
    <div class="video-grid flex flex-wrap justify-around w-full -mx-2">
      {videoLinks.map(v => (
        <Video key={v} video={v} />
      ))}
    </div>
  );
};

export default VideoGrid;
