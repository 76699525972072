import React from "react";

const Resources = () => {
  return (
    <div className="lg:w-8/12 w-11/12 px-2 mb-16 ml-8 lg:ml-12 xl:ml-16 text-gray-800">
      <h2 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-6">Other Resources</h2>
      <ul>
        <li className="text-xl font-semibold mb-3">
          <a href="https://www.laurenbacon.com/impostor-syndrome/" target="_blank" rel="noopener noreferrer">
            Laura Bacon Blog - Overcoming Impostor Syndrome
          </a>
        </li>
        <li className="text-xl font-semibold mb-3">
          <a href="https://medium.com/career-relaunch/overcoming-imposter-syndrome-5246be1fd3b1" target="_blank" rel="noopener noreferrer">
            Medium Article - Overcoming Imposter Syndrome in Your Career: 5 Resources To Help You
          </a>
        </li>
        <li className="text-xl font-semibold mb-3">
          <a href="https://medium.com/swlh/hi-i-am-an-imposter-24b9e6692cfe" target="_blank" rel="noopener noreferrer">
            Lambda Student Medium Article - Hi, I’m an imposter.
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Resources;
