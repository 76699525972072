import React from 'react';
import Logo from '../assets/Lambda_Logo_white.png'

const Navbar = () => {
  return (
    <nav class="bg-teal-500 p-6 w-full mb-12">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <a href="https://www.lambdaschool.com" target="_blank" rel="noopener noreferrer"><img width="130" src={Logo} alt="Lambda School" /></a>
      </div>
    </nav>
  );
}

export default Navbar;